.icon-right  {
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.icon-left {
    position: absolute;
    top: 10px;
    left: 10px;
}

.season-display {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.season-display.winter i {
    color: lightskyblue;
}

.season-display.summer i {
    color: orangered;
}

.winter {
    background-color: aliceblue;
}

.summer {
    background-color: orange;
}

